import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Container, Row, Col } from "react-bootstrap"
import "../components/Blog/blog.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faExternalLink} from "@fortawesome/pro-solid-svg-icons"

const PageTemplate = (props) => {
  const { data, location } = props;
  const pageLocation = location.pathname;
  
  const document = data.contentfulSwimLessonsPage;

  // Render \n to <br />
  const Text = ({ children }) => (
    <p style={{ whiteSpace: 'pre-line' }}>{children}</p>
  )

  const options = {
    renderNode: {
      
      // Render \n to <br />
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,

      [BLOCKS.EMBEDDED_ASSET]: ({
        data: {
          target: { fields },
        },
      }) => (
        <div
          dangerouslySetInnerHTML={{
            __html: `<img src="${fields.file["en-US"].url}" alt="${fields.title["en-US"]}" class="fluid" style="margin: 20px 0 20px 0; max-width: 100%" />`,
          }}
        />
      ),
      [INLINES.HYPERLINK]: ({ data }, children) => {
        console.log(data)        
        return (
         <>
            <a href={data.uri} className="underline" target="_blank" rel="noopener noreferrer">
              {children}{' '} <FontAwesomeIcon
          icon={faExternalLink}
          aria-hidden="true"
          size="xs"
          />
            </a>
         </>
        )
      },

      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const fields = node.data.target.fields.imageLink
        const imgLnk = node.data.target.fields.url
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: `<a href="${imgLnk["en-US"]}"><img src="${fields["en-US"].fields.file["en-US"].url}" alt="${fields.title}"  /></a>`,
            }}
          />
        )
      },
    },
  }

  return (
    <Layout title={document.title}>
      <Container
        style={{
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        <Row style={{
            justifyContent: 'center'
        }}>
          <Col className="blog-content">
            <div
              style={{
                marginTop: "2rem",
                // overflow: 'hidden'
              }}
            >
              <h1 className="document-title">{document.title}</h1>
              <div style={{
                  borderBottom: '4px solid #7FAA74',
                  marginBottom: '2rem',
                  
              }} />
              <p className="blog-text">
              {documentToReactComponents(
                document.childContentfulSwimLessonsPagePageContentRichTextNode.json,
                options
              )}
              </p>
            </div>
          </Col>
        </Row>
        <Row className="my-4">
          <Col className="text-center">
          { pageLocation.includes('nivel') || pageLocation.includes('bebe') 
            ? (
              <>
                <Link to="/summer-swim-lessons-espanol">
                  <FontAwesomeIcon
                  icon={faChevronLeft}
                  aria-hidden="true"
                />
              {" "}Back to Swim Lessons Overview</Link>
              </>
            ) 
            : (
              <>
                <Link to="/summer-swim-lessons">
                  <FontAwesomeIcon
                  icon={faChevronLeft}
                  aria-hidden="true"
                />
              {" "}Back to Swim Lessons Overview</Link>
              </>
    )
          }
                
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const getBlogdocument = graphql`
query ContentfulSwimLessonsPageQuery($slug: String) {
  contentfulSwimLessonsPage(slug: { eq: $slug }) {
    slug
    title
    childContentfulSwimLessonsPagePageContentRichTextNode {
      json
    }
  }
}
`

export default PageTemplate
